// @ts-ignore
/* eslint-disable */
import { request } from '../../../utils/request';

/** 此处后端没有提供注释 POST /margin/change */
export async function adminChangeMargin(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.adminChangeMarginParams,
  options?: { [key: string]: any },
) {
  return request<API.ExchangeMargin>('/margin/change', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 此处后端没有提供注释 POST /margin/close */
export async function marginClose(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.marginCloseParams,
  options?: { [key: string]: any },
) {
  return request<API.ExchangeOrder>('/margin/close', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 此处后端没有提供注释 POST /margin/copy */
export async function adminCopyMargin(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.adminCopyMarginParams,
  options?: { [key: string]: any },
) {
  return request<API.ExchangeOrder>('/margin/copy', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
