import { EditButton, WrapperField } from "react-admin";
import { MyList } from "../../components/MyList";
import { MyField } from "../../components/types";
import { Field, isNotRequired } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/MyCreate";
import MyEdit from "../../components/MyEdit";
import { BooleanTypeChoices, StatusTypeChoices } from "../../model/Core";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { UserWalletTypeChoices } from "../../model/UserWallet";
import {
  ExchangeOrderDirectionChoices,
  ExchangeOrderStatusChoices,
  ExchangeOrderTypeChoices,
} from "../../model/exchange";
import { MyDatagrid } from "../../components/MyDatagrid";
import { userField } from "../../utils/field/userField";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Shanghai");

const fields: MyField<API.Coin>[] = [
  Field.objectId({
    isList: false,
  }),
  Field.text({
    label: "id",
    source: "idx",
    maxWidth: 70,
  }),
  Field.text({
    label: "交易对",
    source: "symbol",
  }),
  Field.select({
    label: "状态",
    source: "status",
    maxWidth: 70,
    choices: ExchangeOrderStatusChoices,
  }),
  Field.select({
    label: "水军",
    source: "isMyUser",
    maxWidth: 50,
    isFilter: true,
    choices: BooleanTypeChoices,
  }),
  userField(),
  Field.select({
    label: "类型",
    maxWidth: 70,
    source: "userWalletType",
    isFilter: false,
    choices: UserWalletTypeChoices,
  }),
  Field.select({
    label: "订单类型",
    source: "type",
    maxWidth: 70,
    choices: ExchangeOrderTypeChoices,
  }),
  Field.select({
    label: "交易方向",
    source: "direction",
    maxWidth: 70,
    choices: ExchangeOrderDirectionChoices,
  }),

  Field.amount({
    label: "挂单价格",
    isList: false,
    source: "price",
  }),
  Field.number({
    label: "挂单数量",
    isList: false,
    source: "number",
  }),
  Field.amount({
    label: "保证金",
    source: "deposit",
  }),
  Field.amount({
    label: "盈亏",
    source: "profitAndLoss",
  }),
  Field.amount({
    label: "关闭后余额",
    source: "userBalance",
  }),
  Field.number({
    label: "杠杆倍数",
    source: "leverageLevel",
  }),
  Field.number({
    label: "成交数量",
    source: "completeNumber",
  }),
  Field.amount({
    label: "成交额",
    source: "completeVolume",
  }),
  Field.amount({
    label: "挂单额",
    isList: false,
    source: "volume",
  }),
  Field.amount({
    label: "手续费",
    source: "fee",
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
    transform: (value) => transformUSTime(value),
  }),
  Field.dateTime({
    label: "完成时间",
    source: "completedTime",
    transform: (value) => transformUSTime(value),
  }),
  Field.dateTime({
    label: "取消时间",
    source: "canceledTime",
    transform: (value) => transformUSTime(value),
  }),
];

const transformUSTime = (value: string) => {
  let res = dayjs.tz(value, "America/Chicago").toDate();
  return res;
};
export const MyExchangeTable = () => {
  return <MyDatagrid fields={fields} rowActions={[]} />;
};

const resource = {
  list: () => {
    return (
      <MyList
        fields={fields}
        filterDefaultValues={{ isMyUser: false }}
        rowActions={[]}
        tableActions={<></>}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: ShoppingBasketIcon,
};

export default resource;
