import * as React from "react";
import { useEffect, useState } from "react";
import { Button, useNotify, useRefresh } from "react-admin";
import { useRecordContext } from "ra-core";
import { ContentCopy } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  Button as MuiButton,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { adminCopyMargin } from "../../../service/agentMargin/api/agentMarginService";

export interface CopyMarginButtonProps {}

export default function CopyMarginButton(props: CopyMarginButtonProps) {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext();
  const [number, setNumber] = useState(record.number);
  const [level, setLevel] = useState(record.level);
  useEffect(() => {
    setNumber(record.number);
    setLevel(record.level);
  }, [record]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    refresh();
  };

  const onSubmit = async () => {
    await adminCopyMargin({
      marginId: record.id as string,
    });

    notify("操作成功");
    handleClose();
  };

  let label = "复制";
  return (
    <>
      <Button
        onClick={(e) => {
          setTimeout(() => {
            handleClickOpen();
          });
        }}
        label={label}
      >
        <ContentCopy />
      </Button>
      {open && (
        <Dialog
          open={open}
          fullWidth
          maxWidth={"xs"}
          onClose={handleClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">{label}</DialogTitle>
          <DialogContent dividers>
            <Box component="form" id={"create"} onSubmit={() => onSubmit()}>
              确认是否复制
            </Box>
          </DialogContent>
          <DialogActions>
            <MuiButton onClick={handleClose}>关闭</MuiButton>
            <MuiButton form={"create"} type="submit">
              确定
            </MuiButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
