import { useInput } from "ra-core";
import * as React from "react";
import { useEffect, useMemo } from "react";
import { Button, CommonInputProps } from "react-admin";
import Box from "@mui/material/Box";
import {
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Locales, LocalesValue } from "../model/Core";
import RemoveCircleSharpIcon from "@mui/icons-material/RemoveCircleSharp";
import remove from "lodash/remove";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { NewRichTextInput } from "./richText/NewRichTextInput";

export interface MyLocalesValueInputProps extends CommonInputProps {
  className?: string;
}

export function MyLocalesValueInput(props: MyLocalesValueInputProps) {
  const {
    className,
    defaultValue = "",
    label,
    format,
    helperText,
    onBlur,
    onChange,
    parse,
    resource,
    source,
    validate,
    ...rest
  } = props;
  const {
    field,
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
    id,
    isRequired,
  } = useInput({
    defaultValue,
    format,
    parse,
    resource,
    source,
    type: "text",
    validate,
    onBlur,
    onChange,
    ...rest,
  });

  const { name, value = [], ref } = field;

  useEffect(() => {
    if (value === "") {
      field.onChange([]);
    }
  }, [value]);
  const localesValue = useMemo(() => {
    if (value === undefined || value === null || value === "") {
      return [];
    }
    return value as LocalesValue[];
  }, [field, field.value]);

  const leftTypes = useMemo(() => {
    let localesArray = [...Locales];
    localesValue.forEach((item) => {
      remove(localesArray, (i) => i === item.locale);
    });
    return localesArray;
  }, [field, field.value]);

  function changeValue(index: number, value: string) {
    const newValue = [...localesValue];
    newValue[index].value = value;

    field.onChange(newValue);
  }

  function changeLocale(locale: string, index: number) {
    const newValue = [...localesValue];
    newValue[index].locale = locale;
    field.onChange(newValue);
  }

  function add() {
    const newValue = [...localesValue, { locale: leftTypes[0], value: "" }];
    field.onChange(newValue);
  }

  function removeByIndex(index: number) {
    const newValue = [...localesValue];
    remove(newValue, (item, i) => i === index);
    field.onChange(newValue);
  }

  return (
    <Box p={"8px 0"}>
      <InputLabel>{label}</InputLabel>
      <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
        {localesValue.map((item, index) => {
          return (
            <Input
              key={index}
              value={item.value}
              onChange={(e) => changeValue(index, e.target.value)}
              startAdornment={
                <InputAdornment sx={{ width: "160px" }} position="start">
                  <LocalesSelect
                    value={item.locale}
                    locales={[item.locale, ...leftTypes]}
                    onChange={(locale) => changeLocale(locale, index)}
                  ></LocalesSelect>
                </InputAdornment>
              }
              endAdornment={
                <IconButton onClick={() => removeByIndex(index)}>
                  <RemoveCircleSharpIcon />
                </IconButton>
              }
            />
          );
        })}
      </Box>
      <Box p={"4px 0"}>
        {leftTypes.length > 0 && (
          <Button
            size={"medium"}
            onClick={() => add()}
            startIcon={<AddCircleIcon />}
            label={"添加"}
          />
        )}
      </Box>
    </Box>
  );
}

function LocalesSelect(props: {
  value: string;
  locales: string[];
  onChange: (e: string) => void;
}) {
  const { value, locales, onChange } = props;
  return (
    <Select<string>
      variant={"standard"}
      disableUnderline={true}
      value={value}
      size={"small"}
      onChange={(e) => onChange(e.target.value)}
    >
      {locales.map((item, i) => {
        return (
          <MenuItem value={item} key={i}>
            {item}
          </MenuItem>
        );
      })}
    </Select>
  );
}
