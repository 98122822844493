import * as React from "react";
import { ReactNode, useEffect } from "react";
import { Editor, EditorContent, EditorOptions, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import TextAlign from "@tiptap/extension-text-align";
import Image from "@tiptap/extension-image";
import TextStyle from "@tiptap/extension-text-style";
import { Color } from "@tiptap/extension-color";
import Highlight from "@tiptap/extension-highlight";
import { styled } from "@mui/material/styles";
import { Labeled } from "ra-ui-materialui";
import { TiptapEditorProvider } from "./TiptapEditorProvider";
import { RichTextInputToolbar } from "./RichTextInputToolbar";

/**
 * A rich text editor for the react-admin that is accessible and supports translations. Based on [Tiptap](https://www.tiptap.dev/).
 * @param props The input props. Accept all common react-admin input props.
 * @param {EditorOptions} props.editorOptions The options to pass to the Tiptap editor.
 * @param {ReactNode} props.toolbar The toolbar containing the editors commands.
 *
 * @example <caption>Customizing the editors options</caption>
 * import { RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';
 * const MyRichTextInput = (props) => (
 *     <RichTextInput
 *         toolbar={<RichTextInputToolbar size="large" />}
 *         label="Body"
 *         source="body"
 *         {...props}
 *     />
 * );
 *
 * @example <caption>Customizing the toolbar size</caption>
 * import { RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';
 * const MyRichTextInput = (props) => (
 *     <RichTextInput
 *         toolbar={<RichTextInputToolbar size="large" />}
 *         label="Body"
 *         source="body"
 *         {...props}
 *     />
 * );
 *
 * @example <caption>Customizing the toolbar commands</caption>
 * import { RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';
 * const MyRichTextInput = ({ size, ...props }) => (
 *     <RichTextInput
 *         toolbar={(
 *             <RichTextInputToolbar>
 *                 <LevelSelect size={size} />
 *                 <FormatButtons size={size} />
 *                 <ColorButtons size={size} />
 *                 <ListButtons size={size} />
 *                 <LinkButtons size={size} />
 *                 <ImageButtons size={size} />
 *                 <QuoteButtons size={size} />
 *                 <ClearButtons size={size} />
 *             </RichTextInputToolbar>
 *         )}
 *         label="Body"
 *         source="body"
 *         {...props}
 *     />
 * );
 */

export type RichTextInputProps = {
  disabled?: boolean;
  readOnly?: boolean;
  editorOptions?: Partial<EditorOptions>;
  toolbar?: ReactNode;
  value?: string;
  onChange: (v: string) => void;
};

export const RichTextInput = (props: RichTextInputProps) => {
  const {
    disabled = false,
    readOnly = false,
    editorOptions = DefaultEditorOptions,
    value,
    toolbar,
    onChange,
  } = props;

  const editor = useEditor({
    ...editorOptions,
    editable: !disabled && !readOnly,
    content: value,
    editorProps: {
      handleClickOn: (view, pos, node) => {
        console.log("handleClickOn", node.attrs);
      },
      attributes: { id: "editor" },
    },
  });

  useEffect(() => {
    if (!editor) return;

    const { from, to } = editor.state.selection;

    editor.commands.setContent(value || "", false, {
      preserveWhitespace: true,
    });

    editor.commands.setTextSelection({ from, to });
  }, [editor, value]);

  useEffect(() => {
    if (!editor) return;

    editor.setOptions({
      editable: !disabled && !readOnly,
      editorProps: {
        attributes: {},
      },
    });
  }, [disabled, editor, readOnly]);

  useEffect(() => {
    if (!editor) {
      return;
    }

    const handleEditorUpdate = () => {
      if (editor.isEmpty) {
        onChange("");
        return;
      }

      const html = editor.getHTML();
      onChange(html);
    };

    editor.on("update", handleEditorUpdate);
    return () => {
      editor.off("update", handleEditorUpdate);
    };
  }, [editor, onChange]);

  return (
    <Labeled fullWidth={true}>
      <RichTextInputContent
        editor={editor}
        toolbar={toolbar || <RichTextInputToolbar />}
      />
    </Labeled>
  );
};

export type RichTextInputContentProps = {
  className?: string;
  editor?: Editor | null;
  toolbar?: ReactNode;
};

/**
 * Extracted in a separate component so that we can remove fullWidth from the props injected by Labeled
 * and avoid warnings about unknown props on Root.
 */
const RichTextInputContent = ({
  className,
  editor,
  toolbar,
}: RichTextInputContentProps) => (
  <Root className={className}>
    <TiptapEditorProvider value={editor!!}>
      {toolbar}
      <EditorContent className={classes.editorContent} editor={editor!!} />
    </TiptapEditorProvider>
  </Root>
);

export const DefaultEditorOptions: Partial<EditorOptions> = {
  extensions: [
    StarterKit,
    Underline,
    Link.configure({
      openOnClick: false,
    }),
    TextAlign.configure({
      types: ["heading", "paragraph"],
    }),
    Image.configure({
      HTMLAttributes: {
        style: "max-width: 100%;",
      },
      inline: true,
    }),
    TextStyle, // Required by Color
    Color,
    Highlight.configure({ multicolor: true }),
  ],
};

const PREFIX = "RaRichTextInput";
const classes = {
  editorContent: `${PREFIX}-editorContent`,
};
const Root = styled("div", {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  [`& .${classes.editorContent}`]: {
    width: "100%",
    "& .ProseMirror": {
      minHeight: "400px",
      backgroundColor: theme.palette.background.default,
      borderColor:
        theme.palette.mode === "light"
          ? "rgba(0, 0, 0, 0.23)"
          : "rgba(255, 255, 255, 0.23)",
      borderRadius: theme.shape.borderRadius,
      borderStyle: "solid",
      borderWidth: "1px",
      padding: theme.spacing(1),

      '&[contenteditable="false"], &[contenteditable="false"]:hover, &[contenteditable="false"]:focus': {
        backgroundColor: theme.palette.action.disabledBackground,
      },

      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
      "&:focus": {
        backgroundColor: theme.palette.background.default,
      },
      "& p": {
        margin: "0 0 1em 0",
        "&:last-child": {
          marginBottom: 0,
        },
      },
    },
  },
}));
