import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import PropTypes from "prop-types";
import { Identifier, RaRecord, SortPayload, useListContext } from "ra-core";
import * as React from "react";
import {
  ComponentType,
  FC,
  isValidElement,
  ReactElement,
  useMemo,
} from "react";
// import { DatagridContextProvider } from "ra-ui-materialui";
import {
  DataGridPro,
  DataGridProProps,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  gridFilteredDescendantCountLookupSelector,
  GridRenderCellParams,
  useGridApiContext,
  useGridSelector,
  zhCN,
} from "@mui/x-data-grid-pro";
import DatagridContextProvider from "ra-ui-materialui/dist/cjs/list/datagrid/DatagridContextProvider";
// import { DatagridHeader } from "./DatagridHeader";
// import DatagridLoading from "./DatagridLoading";
// import DatagridBody, { PureDatagridBody } from "./DatagridBody";
import { GridActionsCellItemProps } from "@mui/x-data-grid/components/cell/GridActionsCellItem";
import {
  GridColumns,
  GridEnrichedColDef,
} from "@mui/x-data-grid/models/colDef/gridColDef";
import { GridDensity } from "@mui/x-data-grid/models/gridDensity";
import {
  BulkActionsToolbar,
  BulkDeleteButton,
  DatagridClasses,
  DatagridRoot,
  RecordContextProvider,
  RowClickFunction,
} from "react-admin";
import { ExcludeProps, MyField } from "./types";
import omit from "lodash/omit";
import { MyProActions } from "./MyProActions";
import { Box, BoxProps, IconButton, Theme, useMediaQuery } from "@mui/material";
import {
  GridGroupingColDefOverride,
  GridGroupingColDefOverrideParams,
} from "@mui/x-data-grid-pro/models/gridGroupingColDefOverride";
import { GridRowTreeNodeConfig } from "@mui/x-data-grid";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const defaultBulkActionButtons = <BulkDeleteButton />;

const SIZE_DENSITY = {
  small: "compact",
  medium: "standard",
  large: "comfortable",
};

//'string' | 'number' | 'date' | 'dateTime' | 'boolean' | 'singleSelect' | 'actions'

export const MyProDataGrid: FC<MyDataGridProps> = React.forwardRef(
  (props, ref) => {
    const {
      fields,
      rowActions,
      bulkActionButtons = defaultBulkActionButtons,
      isRowExpandable,
      size = "small",
      expandSingle = false,
    } = props;

    const {
      sort,
      data = [],
      isLoading,
      onSelect,
      // onToggleItem,
      selectedIds,
      setSort,
    } = useListContext(props);

    const contextValue = useMemo(() => ({ isRowExpandable, expandSingle }), [
      isRowExpandable,
      expandSingle,
    ]);
    const isXSmall = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down("sm")
    );

    const columns: GridColumns = fields.map((f, i) => {
      const {
        component: FieldComponent,
        source,
        label,
        props,
        sortable = false,
      } = f;

      let column: GridEnrichedColDef = {
        type: "string",
        field: source || "",
        headerName: label,
        sortable: sortable,
        renderCell: (params) => {
          if (!FieldComponent) {
            return <></>;
          }
          return (
            <RecordContextProvider<any> value={params.row}>
              <FieldComponent
                source={source}
                label={label}
                sortable={sortable}
                {...omit(props, ExcludeProps)}
              />
            </RecordContextProvider>
          );
        },
      };
      const mix = (field: string) => {
        // @ts-ignore
        const v = f[field];
        if (!isUndefined(v) && !isNull(v)) {
          // @ts-ignore
          column[field] = v;
        }
      };
      mix("width");
      mix("maxWidth");
      mix("minWidth");
      if (f?.fullWidth) {
        column.flex = 1;
      }
      if (f?.props?.fullWidth) {
        column.flex = 1;
      }
      // if (!f?.props?.source) {
      //   column.type = "actions";
      //   column.field = "actions";
      // }
      return column;
    });
    let width = 8 + Math.min(rowActions.length, 2) * 70;
    if (rowActions.length > 2) {
      width += 40;
    }
    if (isXSmall) {
      width = 40;
    }
    const renderCell =
      props.renderActionCell ||
      ((params: GridRenderCellParams<any, any, any>) => {
        return <MyProActions rowActions={rowActions} params={params} />;
      });
    columns.push({
      field: "actions",
      type: "actions",
      minWidth: width,
      width: width,
      renderCell: renderCell,
    });
    // columns.push({
    //   field: "actions",
    //   headerName: "",
    //   minWidth: 200,
    //   renderCell: (params: GridRenderCellParams<any, any, any>) => {
    //     return rowActions.map((a, i) => (
    //       <React.Fragment key={i}>
    //         <BaseGridActionsCellItem button={a} params={params} />
    //       </React.Fragment>
    //     ));
    //   },
    // });
    const density = SIZE_DENSITY[size] as GridDensity;

    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    return (
      <DatagridContextProvider value={contextValue}>
        <DatagridRoot>
          {bulkActionButtons !== false ? (
            <BulkActionsToolbar selectedIds={selectedIds}>
              {isValidElement(bulkActionButtons)
                ? bulkActionButtons
                : defaultBulkActionButtons}
            </BulkActionsToolbar>
          ) : null}
          <div className={`${DatagridClasses.tableWrapper}`}>
            <DataGridPro
              // //@ts-ignore
              // apiRef={apiRef}

              getTreeDataPath={props.getTreeDataPath}
              initialState={{
                pinnedColumns: {
                  left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
                  right: ["actions"],
                },
              }}
              localeText={zhCN.components.MuiDataGrid.defaultProps.localeText}
              hideFooter
              autoHeight={true}
              pagination={false}
              disableColumnFilter
              density={density}
              checkboxSelection
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              columns={columns}
              groupingColDef={props.groupingColDef}
              rows={data}
              isGroupExpandedByDefault={props.isGroupExpandedByDefault}
              treeData={!!props.getTreeDataPath}
              // onCellClick={(params) => {
              //   onToggleItem(params.id);
              // }}
              onSortModelChange={(model, details) => {
                if (model.length > 0) {
                  let m = model[0];
                  setSort({
                    field: m.field,
                    order: m?.sort?.toUpperCase() || "asc",
                  });
                }
              }}
              sortModel={[
                {
                  field: sort.field,
                  sort: sort.order.toLowerCase() === "asc" ? "asc" : "desc",
                },
              ]}
              selectionModel={selectedIds}
              onSelectionModelChange={(newSelectionModel) => {
                onSelect(newSelectionModel);
              }}
              loading={isLoading}
            />
          </div>
        </DatagridRoot>
      </DatagridContextProvider>
    );
  }
);

MyProDataGrid.propTypes = {
  // @ts-ignore
  body: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  tableActions: PropTypes.any,
  // @ts-ignore-line
  bulkActionButtons: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  className: PropTypes.string,
  sort: PropTypes.any,
  data: PropTypes.arrayOf(PropTypes.any),
  empty: PropTypes.element,
  // @ts-ignore
  expand: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  // @ts-ignore
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  hover: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSelect: PropTypes.func,
  onToggleItem: PropTypes.func,
  resource: PropTypes.string,
  rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowStyle: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  setSort: PropTypes.func,
  total: PropTypes.number,
  isRowSelectable: PropTypes.func,
  isRowExpandable: PropTypes.func,
  expandSingle: PropTypes.bool,
  getTreeDataPath: PropTypes.func,
  isGroupExpandedByDefault: PropTypes.func,
  groupingColDef: PropTypes.any,
};

export interface MyDataGridProps<RecordType extends RaRecord = any> {
  renderActionCell?: (
    params: GridRenderCellParams<any, any, any>
  ) => React.ReactNode;
  fields: MyField<RecordType>[];
  body?: ReactElement | ComponentType;
  rowActions: React.ReactElement<GridActionsCellItemProps>[];
  className?: string;
  bulkActionButtons?: ReactElement | false;
  expand?:
    | ReactElement
    | FC<{
        id: Identifier;
        record: RecordType;
        resource: string;
      }>;
  header?: ReactElement | ComponentType;
  hover?: boolean;
  empty?: ReactElement;
  isRowSelectable?: (record: RecordType) => boolean;
  isRowExpandable?: (record: RecordType) => boolean;
  rowClick?: string | RowClickFunction;
  rowStyle?: (record: RecordType, index: number) => any;
  size?: "medium" | "small" | "large";
  // can be injected when using the component without context
  sort?: SortPayload;
  data?: RecordType[];
  isLoading?: boolean;
  onSelect?: (ids: Identifier[]) => void;
  onToggleItem?: (id: Identifier) => void;
  setSort?: (sort: SortPayload) => void;
  selectedIds?: Identifier[];
  expandSingle?: boolean;
  total?: number;
  getTreeDataPath?: (row: RecordType) => string[];
  isGroupExpandedByDefault?: (node: GridRowTreeNodeConfig) => boolean;
  groupingColDef?:
    | GridGroupingColDefOverride<RecordType>
    | ((
        params: GridGroupingColDefOverrideParams
      ) => GridGroupingColDefOverride<RecordType> | undefined | null);
}

MyProDataGrid.displayName = "MyProDataGrid";
