import { MyList } from "../../components/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/MyCreate";
import MyEdit from "../../components/MyEdit";
import { BooleanTypeChoices } from "../../model/Core";
import { MyDatagrid } from "../../components/MyDatagrid";
import { userField } from "../../utils/field/userField";
import CollectionsIcon from "@mui/icons-material/Collections";

const fields: MyField<API.Coin>[] = [
  Field.objectId(),
  Field.ref({
    label: "活期计划",
    source: "planId",
    isRequired: true,
    isList: true,
    isFilter: false,
    reference: "fundTermPlans",
    referenceLabel: "id",
    originSource: "planId",
    fullWidth: true,
    translateLabel: (record: any) => record?.title?.[0].value,
  }),
  Field.select({
    label: "水军",
    source: "isMyUser",
    maxWidth: 50,
    isFilter: true,
    choices: BooleanTypeChoices,
  }),
  userField(),
  Field.number({
    label: "认购份额",
    source: "number",
  }),
  Field.text({
    label: "币种",
    source: "coinSymbol",
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
];

export const FundTermUserTable = () => {
  return <MyDatagrid fields={fields} rowActions={[]} />;
};

const resource = {
  list: () => {
    return (
      <MyList
        fields={fields}
        filterDefaultValues={{ isMyUser: false }}
        rowActions={[]}
        tableActions={<></>}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: CollectionsIcon,
};

export default resource;
