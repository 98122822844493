import { buildField } from "../Field";
import { MyField } from "../../components/types";
import { ImageField, ImageInput } from "react-admin";
import * as React from "react";

export interface ImgArgs {
  label: string;
  source: string;
}

export function img(args: ImgArgs, field?: MyField) {
  return buildField(
    {
      label: args.label,
      source: args.source,

      sortable: false,
      isFilter: false,
      component: ImageField,
      props: {
        source: `${args.source}.src`,
        sx: {
          "& img": {
            maxWidth: "50px !important",
            maxHeight: "30px !important",
            margin: "0 !important",
            objectFit: "contain",
          },
          "&": {
            maxWidth: 50,
            maxHeight: 30,
            objectFit: "contain",
            overflow: "hidden",
          },
        },
      },
      editComponent: ImageInput,
      editProps: {
        accept: "image/*",
        children: <ImageField source="src" title="src" />,
      },
    },
    field
  );
}
