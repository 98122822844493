import { MyField } from "../components/types";
import omit from "lodash/omit";
import { localRef } from "./field/localRef";
import { ref } from "./field/ref";
import { objectId } from "./field/objectId";
import { text } from "./field/text";
import { maxText } from "./field/maxText";
import { select } from "./field/select";
import { editSelect } from "./field/editSelect";
import { array } from "./field/array";
import { amount } from "./field/amount";
import { editAmount } from "./field/editAmount";
import { number } from "./field/number";
import { editNumber } from "./field/editNumber";
import { editArray } from "./field/editArray";
import { dataTime } from "./field/dateTime";
import { data } from "./field/date";
import { editData } from "./field/editDate";
import { img } from "./field/img";
import { localesValue } from "./field/LocalesValue";
import { richText } from "./field/richText";
import { percent } from "./field/percent";
import { functionField } from "./field/function";
import { userField } from "./field/userField";

export const buildField = (fieldSrc: MyField, field?: MyField): MyField => {
  let obj = {
    sortable: true,
    isPrimary: true,
    isFilter: true,
    isCreate: true,
    isEdit: true,
    isUpdate: true,
    editProps: {
      ...fieldSrc.editProps,
      ...field?.editProps,
    },
    filterProps: {
      ...fieldSrc.filterProps,
      ...field?.filterProps,
    },
    props: {
      ...fieldSrc.props,
      ...field?.props,
    },

    ...omit(fieldSrc, ["editProps", "filterProps", "props"]),
    ...omit(field, ["editProps", "filterProps", "props"]),
  };

  return omit(obj);
};

export const isNotRequired = {
  editProps: {
    validate: [],
  },
};

export const Field = {
  userField: userField,
  objectId: objectId,
  text: text,
  maxText: maxText,
  select: select,
  editSelect: editSelect,
  array: array,
  amount: amount,
  editAmount: editAmount,
  number: number,
  percent: percent,
  functionField: functionField,
  editNumber: editNumber,
  editArray: editArray,
  dateTime: dataTime,
  date: data,
  editDate: editData,
  ref: ref,
  localRef: localRef,
  img: img,
  localesValue: localesValue,
  richText: richText,
};
