import { TextFieldProps } from "react-admin";
import { useRecordContext } from "ra-core";
import * as React from "react";
import { FC, useMemo } from "react";
import get from "lodash/get";
import { LocalesValue } from "../model/Core";
import Box from "@mui/material/Box";

interface MyLocalesValueFieldProps extends TextFieldProps {}

export const MyLocalesValueField: FC<MyLocalesValueFieldProps> = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  const value = get(record, source as string);
  const localesValue = useMemo(() => {
    if (value === undefined || value === null || value === "") {
      return [];
    }
    return value as LocalesValue[];
  }, [value]);

  if (value === "") {
    return <></>;
  }
  return (
    <Box>
      {localesValue.map((v, i) => (
        <span style={{ fontSize: "12px", padding: "0 4px" }} key={i}>
          {v.locale + ": " + v.value}
        </span>
      ))}
    </Box>
  );
};
