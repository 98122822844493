import Box from "@mui/material/Box";
import { useState } from "react";

import {
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
} from "react-admin";

import SubMenu from "./layout/SubMenu";
import users from "./pages/User";
import userWallets from "./pages/user/UserWallet";
// import coinProtocols from "./pages/coin/CoinProtocol";
import exchangeOrders from "./pages/exchange/ExchangeOrder";
import { icon as ExchangeMarginIcon } from "./pages/exchange/ExchangeMargin";
import fundCurrentUsers from "./pages/fund/FundCurrentUser";
import nftOrders from "./pages/nft/NftOrder";

type MenuName =
  | "menuOrder"
  | "menuExchange"
  | "menuCoin"
  | "menuCatalog"
  | "menuUsers"
  | "menuAdmins"
  | "menuContent";

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuCatalog: true,
    menuUsers: true,
    menuExchange: true,
    menuAdmins: true,
    menuContent: true,
    menuOrder: true,
    menuCoin: true,
  });
  const [open] = useSidebarState();

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <DashboardMenuItem />
      <SubMenu
        handleToggle={() => handleToggle("menuUsers")}
        isOpen={state.menuUsers}
        name="会员管理"
        icon={<users.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/users"
          state={{ _scrollToTop: true }}
          primaryText={"会员管理"}
          leftIcon={<users.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/userWallets"
          state={{ _scrollToTop: true }}
          primaryText={"钱包管理"}
          leftIcon={<userWallets.icon />}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuOrder")}
        isOpen={state.menuOrder}
        name="订单管理"
        icon={<exchangeOrders.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/exchangeMarginsLeverage"
          state={{ _scrollToTop: true }}
          primaryText={"杠杆仓位"}
          leftIcon={<ExchangeMarginIcon sx={{ color: "#3ac181" }} />}
          dense={dense}
        />
        <MenuItemLink
          to="/exchangeMarginsContract"
          state={{ _scrollToTop: true }}
          primaryText={"合约仓位"}
          leftIcon={<ExchangeMarginIcon sx={{ color: "#f74a4c" }} />}
          dense={dense}
        />
        <MenuItemLink
          to="/exchangeOrders"
          state={{ _scrollToTop: true }}
          primaryText={"交易订单"}
          leftIcon={<exchangeOrders.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/nftOrders"
          state={{ _scrollToTop: true }}
          primaryText={"NFT订单"}
          leftIcon={<nftOrders.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/fundTermUsers"
          state={{ _scrollToTop: true }}
          primaryText={"基金持仓"}
          leftIcon={<fundCurrentUsers.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/fundCurrentUsers"
          state={{ _scrollToTop: true }}
          primaryText={"活期持仓"}
          leftIcon={<fundCurrentUsers.icon />}
          dense={dense}
        />
      </SubMenu>
    </Box>
  );
};

export default Menu;
