import { MyField } from "../../components/types";
import { base, BaseArgs } from "./base";
import { MyLocalesValueInput } from "../../components/MyLocalesValueInput";
import { MyLocalesValueField } from "../../components/MyLocalesValueField";

export interface LocalesValueArgs extends BaseArgs {}

const defaultArgs: Partial<LocalesValueArgs> = {
  isRequired: false,
  fullWidth: false,
};

export function localesValue(args: LocalesValueArgs, field?: MyField) {
  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: MyLocalesValueField,
      editComponent: MyLocalesValueInput,
      filterProps: {
        alwaysOn: true,
        clearAlwaysVisible: true,
        resettable: true,
      },
    },
    field
  );
}
